@media screen and (max-width: 900px) {
    .mob-box {
        flex-wrap: wrap !important;
        grid-template-columns: repeat(1, 1fr) !important;
    }
    .container {
        max-width: 90% !important;
    }
    h1, h2 {
        font-size: 2em !important;
    }
}