:root {
   --art-img-size: 400px;
}

.text-art-sm {
   font-size: 1em;
   color: white;
   font-family: 'Courier New', Courier, monospace;
}

.bonkart {
   display: flex;
   flex-direction: column;
   gap: 50px;
   padding: 50px 0;
}
.ba-top {
   background-color: var(--color-orange-dark);
   border-radius: 20px;
   padding: 30px;
   display: flex;
   justify-content: space-between;
   flex-wrap: wrap;
}
.ba-top > div:first-child {
   display: flex;
   flex-wrap: wrap;
}
.ba-top > div:first-child > h1 {
   font-size: 3em;
}
.ba-top > div:nth-child(2) {
   border-left: 3px solid white;
   width: 40%;
   color: white;
   display: flex;
   justify-self: center;
   align-items: center;
   padding: 0px 5%;
}
.ba-top img {
   width: 190px;
   height: 190px;
}
.ba-content {
   background-color: var(--color-orange);
   border-radius: 20px;
   display: grid;
   grid-template-columns: repeat(auto-fit, minmax(var(--art-img-size), 1fr));
   gap: 30px;
   padding: 30px 0;
}
.ba-content a > div {
   display: flex;
   justify-content: center;
   align-items: center;
}
.ba-content a > div > div {
   width: var(--art-img-size);
   border-radius: 20px;
   background-color: var(--color-orange-dark);
   transition: all 0.3s ease-in-out;
}
.ba-content img {
   width: var(--art-img-size);
   border-radius: 20px 20px 0px 0px;
}
.ba-content a > div > div > div {
   padding: 30px;
   position: relative;
   overflow: hidden;
}
.ba-content a > div > div:hover {
   transform: scale(1.05);
   filter: brightness(1.2);
   background-color: #dd4d00;
}
.ba-content .shine {
   background-color: rgb(255, 255, 255);
   opacity: 0.1;
   width: 220px;
   transform: translateY(-150px) translateX(-300px) rotate(30deg);
   height: 300px;
   position: absolute;
}
.ba-content a > div > div:hover .shine {
   transition: transform 0.4s ease-in-out;
   transform: translateY(-150px) translateX(450px) rotate(30deg);
}

.swiper-wrapper {
   padding-bottom: 50px;
}

.swiper-slide {
   /* height: 500px !important; */
   display: flex !important;
   justify-content: center !important;
   align-items: center !important;
   height: auto !important;
}

.swiper-pagination-bullet {
   width: 10px !important;
   height: 10px !important;
}

.bonkart-swipe {
   border: 10px solid white;
   width: auto;
   max-height: 600px;
   max-width: 40vw;
   position: relative;
   overflow: hidden;
   display: flex;
   justify-content: center;
}

/* .bonkart-swipe .bonkart-swiper-image {
   height: 100%;

   width: 100%;
   background-repeat: no-repeat;
   background-size: cover;
   background-position: center;
} */

.bonkart-swipe .bonkart-swiper-description {
   /* border: 10px solid white; */
   position: absolute;
   border-top: 2px white solid;
   /* bottom: -100%; */
   /* opacity: 0; */
   bottom: 0;
   left: 0;
   width: calc(100% - 20px);
   background-color: rgba(91, 91, 91, 0.778);
   padding: 20px 10px;
   transition: all 0.3s ease-in-out;
}

.bonkart-swipe .bonkart-swiper-description h2 {
   font-size: 15px !important;
}

.swiper-button-prev,
.swiper-button-next {
   color: white !important;
   transition: all 0.1s ease-in-out;
}

.swiper-button-prev:after,
.swiper-button-next:after {
   font-weight: bolder !important;
}

.swiper-button-prev:hover,
.swiper-button-next:hover {
   transform: scale(1.1);
}
.swiper-pagination-bullet-active {
   background-color: white !important;
}

@media only screen and (max-width: 900px) {
   .bonkart {
      padding: 50px 5%;
   }
   .ba-top > div:nth-child(2) {
      border: 0px !important;
      width: 100%;
      padding: 0px;
      padding-top: 20px;
   }
   .ba-content img,
   .ba-content a > div > div,
   .ba-content a > div,
   .ba-content > a {
      width: 300px !important;
      max-width: 300px !important;
   }
   .ba-content {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
   }

   .bonkart-swipe {
      max-width: 80vw;
      max-height: 80vw;
   }

   .bonkart-swipe .bonkart-swiper-description h2 {
      /* font-size: 12px; */
   }
}
