.card {
   border: 5px solid white;
   border-radius: var(--border-radius);
   padding: 30px;
   min-width: 100px;
   position: relative;
}

.gap {
   gap: 010 px !important;
}

.card img.top-logo {
   position: absolute;
   left: 50%;
   top: 0;
   width: 60px;
   height: 60px;
   transform: translate(-50%, -50%);
}

.card-box{
   min-height: 200px;
}

.card h1 {
   color: white;
   font-size: 20px;
}

.card h2 {
   color: white;
   font-size: 3em;
   line-height: 0.8em;
}

.card h3 {
   line-height: 2;
}

.card.big {
   background: transparent linear-gradient(180deg, var(--color-orange) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
}

.card.big h1 {
   font-size: 40px !important;
}

.card.big h3 {
   font-size: 20px !important;
}

.card.yellow {
   background: transparent linear-gradient(90deg, var(--color-orange) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
}

.card.orange {
   background: transparent linear-gradient(90deg, var(--color-orange-dark) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
}

.card.transparent {
   background: transparent;
}