/* ==================== [GENERAL] ==================== */
:root {
   --rotate-time: 0.5s;
}
.keyprojects-container {
   /* padding: 50px 0; */
}
.keyprojects-container button {
   background-color: var(--color-orange);
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   border: 0px;
   rotate: 45deg;
   transition: background-color 0.2s ease-in-out, rotate var(--rotate-time) ease-in-out;
}
.keyprojects-container button:hover {
   cursor: pointer;
   background-color: var(--color-orange-dark);
}
.keyprojects-container button.rotate {
   rotate: 0deg;
}
/* ==================== [TITLE] ==================== */
.title {
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   overflow: hidden;
}
.title h1 {
   font-size: 5em;
   color: var(--color-red-text);
}
/* ==================== [EXPAND] ==================== */
:root {
   /* expand settings */
   --expand-padding: 30px;
}
.expand-container {
   display: flex;
}
.expand-container .big {
   width: 60%;
   padding: 50px;
}
.expand-container .small {
   width: 40%;
   padding: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.kp-img {
   transition: all var(--rotate-time) ease-in-out;
}
.kp-img.open {
   transform: scale(1.1);
}
.expand-container h2 {
   font-size: 5em;
   letter-spacing: 5px;
}
.expand-container button {
   min-width: 60px;
   min-height: 60px;
}
.expand-container .big > div:nth-child(1) {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-right: 20px;
   overflow: hidden;
   cursor: pointer;
}
.expand-container .big > div:nth-child(3) {
   height: 0px;
   border-bottom: 3px solid white;
   transition: height var(--rotate-time) ease-in-out;
   overflow: hidden;
   color: white;
   display: flex;
   flex-direction: column;
   gap: 30px;
}
/* INDIVIDUAL HEIGHT REMOVED! */
.expand-container .big > div:nth-child(3).a {
   height: max-content;
   padding-bottom: 25px;
}
.expand-container .big > div:nth-child(3).b {
   height: max-content;
   padding-bottom: 25px;
}
.expand-container .big > div:nth-child(3).c {
   height: max-content;
   padding-bottom: 25px;
}
.expand-container .big > div:nth-child(3).d {
   height: max-content;
   padding-bottom: 25px;
}
.expand-container .big > div:nth-child(3).e {
   height: max-content;
   padding-bottom: 25px;
}
.expand-container .big > div:nth-child(2) {
   border-top: 3px solid white;
   padding-top: var(--expand-padding);
   padding-bottom: var(--expand-padding);
   color: white;
}
/* ==================== [INFO GRID] ==================== */
.info-grid {
   background-color: var(--color-yellow);
   padding: 30px;
   display: grid;
   border-radius: 20px;
   grid-template-columns: repeat(3, 1fr);
   gap: 20px;
}
.info-grid > div div:first-child {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 10px;
   margin-bottom: 10px;
   border-bottom: 3px solid white;
   cursor: pointer;
   white-space: nowrap;
}
.info-grid h3 {
   font-weight: normal;
   font-size: 20px;
   text-transform: uppercase;
}
.info-grid button {
   min-height: 30px;
   min-width: 30px;
}
.info-grid > div div:nth-child(2) {
   height: 0px;
   overflow: hidden;
   color: white;
   padding: 0px 10px;
   transition: height var(--rotate-time) ease-in-out;
}
.kp-button-container {
   display: flex;
   justify-content: center;
   width: 100%;
}

@media only screen and (max-width: 1800px) {
   .keyprojects-container {
      padding: 50px 5%;
   }
   .keyprojects-container .big {
      width: 100% !important;
      padding: 25px 0 !important;
   }
   .keyprojects-container button {
      display: none;
   }
}

@media only screen and (max-width: 1000px) {
   .expand-container {
      flex-direction: column-reverse !important;
   }
   .kp-img {
      width: 50%;
   }
}

.row {
   flex-direction: row;
 }
 
 .row-reverse {
   flex-direction: row-reverse;
 }