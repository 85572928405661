.social {
   padding: 50px 0px;
   display: flex;
   align-items: center;
   justify-content: center;
}

.social>div {
   display: flex;
   align-items: center;
   justify-content: center;
   flex-wrap: wrap;
}

.social>div div {
   padding: 50px;
   display: grid;
   grid-template-columns: repeat(3, 1fr);
}

.social a:hover {
   transform: scale(1.05);
}

.social>div div img {
   padding: 20px;
}