/* ==================== [GENERAL] ==================== */
:root {
   --rotate-time: 0.5s;
}
.about-container {
   padding: 50px 0;
}
.about-container button {
   background-color: var(--color-orange);
   display: flex;
   justify-content: center;
   align-items: center;
   border-radius: 50%;
   border: 0px;
   rotate: 45deg;
   transition: background-color 0.2s ease-in-out, rotate var(--rotate-time) ease-in-out;
}
.about-container button:hover {
   cursor: pointer;
   background-color: var(--color-orange-dark);
}
.about-container button.rotate {
   rotate: 0deg;
}
/* ==================== [VIDEO] ====================test */
.video {
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
   flex-direction: column;
   overflow: hidden;
}
.video h1 {
   font-size: 5em;
   color: var(--color-red-text);
}

.video div {
   margin-top: 20px;
   position: relative;
   width: 100%;
   padding-bottom: 56.25%; /* 16:9 */
   overflow: hidden;
   border-radius: 20px;
}
.video iframe {
   position: absolute;
   top: 0;
   left: 0;
   width: 100%;
   height: 100%;
   border: 0;
}
/* ==================== [EXPAND] ==================== */
:root {
   /* expand settings */
   --expand-padding: 30px;
   --expand-height-utility: max-content;
   --expand-height-distribution: max-content;
}
.expand-container {
   display: flex;
}
.expand-container .big {
   width: 60%;
   padding: 50px;
}
.expand-container .small {
   width: 40%;
   padding: 50px;
   display: flex;
   justify-content: center;
   align-items: center;
}
.expand-container h2 {
   font-size: 5em;
   letter-spacing: 5px;
}
.expand-container button {
   min-width: 60px;
   min-height: 60px;
}
.expand-container .big > div:nth-child(1) {
   display: flex;
   align-items: center;
   justify-content: space-between;
   padding-right: 20px;
   overflow: hidden;
   cursor: pointer;
}
.expand-container .big > div:nth-child(3) {
   height: 0px;
   border-bottom: 3px solid white;
   transition: height var(--rotate-time) ease-in-out;
   overflow: hidden;
   color: white;
}
.expand-container .big > div:nth-child(3).expand-utility {
   height: var(--expand-height-utility);
   padding-bottom: 30px;
}
.expand-container .big > div:nth-child(3).expand-distribution {
   height: var(--expand-height-distribution);
   padding-bottom: 30px;
}
.expand-container .big > div:nth-child(2) {
   border-top: 3px solid white;
   padding-top: var(--expand-padding);
   padding-bottom: var(--expand-padding);
   color: white;
}
/* ==================== [INFO GRID] ==================== */
.info-grid {
   background-color: var(--color-yellow);
   padding: 30px;
   display: grid;
   border-radius: 20px;
   grid-template-columns: repeat(3, 1fr);
   gap: 20px;
}
.info-grid > div div:first-child {
   display: flex;
   justify-content: space-between;
   align-items: center;
   padding: 10px 10px;
   margin-bottom: 10px;
   border-bottom: 3px solid white;
   cursor: pointer;
   white-space: nowrap;
}
.info-grid h3 {
   font-weight: normal;
   font-size: 20px;
   text-transform: uppercase;
}
.info-grid button {
   min-height: 30px;
   min-width: 30px;
}
.info-grid > div div:nth-child(2) {
   height: 0px;
   overflow: hidden;
   color: white;
   padding: 0px 10px;
   transition: height var(--rotate-time) ease-in-out;
}
/* ==================== [INFO INDIVIDUAL HEIGHTS] ==================== */
.info-grid > div div:nth-child(2).a {
   height: 190px;
}
.info-grid > div div:nth-child(2).b {
   height: 50px;
}
.info-grid > div div:nth-child(2).c {
   height: 120px;
}
.info-grid > div div:nth-child(2).d {
   height: 240px;
}
.info-grid > div div:nth-child(2).e {
   height: 290px;
}
.info-grid > div div:nth-child(2).f {
   height: 190px;
}
.info-grid > div div:nth-child(2).g {
   height: 140px;
}
.info-grid > div div:nth-child(2).h {
   height: 100px;
}
/* ==================== [INFO GRID] ==================== */
.timeline {
   background-color: red;
   margin-top: 50px;
   border-radius: 20px;
   background-color: var(--color-yellow);
   padding: 30px;
   display: flex;
}
.timeline > div {
   color: white;
   text-transform: uppercase;
   width: 100%;
}
.timeline > div > div {
   height: 20px;
   border-top: 3px solid white;
   border-left: 3px solid white;
   margin-bottom: 5px;
}
.timeline p {
   font-size: 1em;
}
/* ==================== [TIMELINE SIZE] ==================== */
.timeline .a {
   width: 21%;
}
.timeline .b {
   width: 15.8%;
}
.timeline .c {
   width: 10.5%;
}
.timeline .d {
   width: 5.3%;
}
.timeline .e {
   width: 21%;
}
.timeline .f {
   width: 15.8%;
}
.timeline .g {
   width: 5.3%;
}
.timeline .h {
   width: 5.3%;
}

.about-container .subtitle {
   color: white;
   font-size: 25px;
   text-transform: uppercase;
}

@media only screen and (max-width: 1200px) {
   .timeline {
      display: none;
   }
}

@media only screen and (max-width: 1850px) {
   .about-container {
      padding: 50px 5%;
   }
   .about-container .big {
      width: 100% !important;
      padding: 25px 0 !important;
   }
   .about-container button {
      display: none;
   }
   .expand-container .big > div:nth-child(3) {
      border-bottom: 0 !important;
      height: auto !important;
   }
   .expand-container .small {
      display: none !important;
   }
}
