
.privacy-content > h1 {
    font-family: 'Herborn';
    font-size: 100px;
}

.privacy-content > h2 {
    font-family: 'Herborn';
    font-size: 50px;
}

.privacy-content > h3{
    font-family: 'Herborn';
    font-size: 30px;
}

.privacy-content > p {
    padding: 20px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
}

.privacy-content > ul li {
    padding: 4px;
    font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
    font-size: 20px;
}