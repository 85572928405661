:root {
   --integrations-container-border-radius: 20px;
   --integrations-container-paddings: 40px;
}

.integrations-container {
   padding-top: 50px;
}

.head {
   display: flex;
   align-items: center;
   flex-direction: column;
   gap: 50px;
   padding-left: 15%;
   padding-right: 15%;
}

.head h1 {
   font-size: 5em;
   color: var(--color-red-text);
}

.head ul {
   display: flex;
   justify-content: space-between;
   width: 90%;
   padding: 0px;
   margin: 0px;
   flex-wrap: wrap;
}

.head li {
   list-style-type: none;
   cursor: pointer;
}

.head li a {
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
   font-size: 0.8em;
   text-transform: uppercase;
   letter-spacing: 2px;
   transition: 0s;
   transition: all 0.2s ease-in-out;
}

.head li a:hover {
   color: #e9e9e9;
}

.head-li-selected {
   background-color: var(--color-red-text);
   padding: 5px;
   border-radius: 5px;
   color: white !important;
}

.grid {
   display: grid;
   grid-template-columns: repeat(3, minmax(300px, 1fr));
   gap: 20px;
   margin-top: 30px;
   /* padding-left: 15%; */
   /* padding-right: 15%; */
   flex-wrap: wrap;
}

.grid div.grid-container {
   border-radius: 15px;
   border: 3px solid white;
   background: transparent linear-gradient(90deg, var(--color-orange-dark) 0%, var(--color-orange) 100%) 0% 0% no-repeat padding-box;
   display: flex;
   overflow: hidden;
   gap: 20px;
   padding: 20px;
   height: 80px;
   transition: all 0.15s ease-in-out;
}
.grid div.grid-container:hover {
   filter: brightness(1.2);
}

.grid h2 {
   color: white;
   letter-spacing: 1px;
   font-size: 1.3em;
}

.grid p {
   font-size: 1em;
   font-weight: 400;
   color: white;
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3;
   line-clamp: 3;
   -webkit-box-orient: vertical;
}

.grid.second {
   margin-top: 50px;
   grid-template-columns: repeat(2, 1fr);
}

.grid.second div div {
   background: transparent;
   flex-direction: column;
   align-items: flex-start;
   justify-content: flex-start;
   border: 0px;
   border-radius: 0px;
   padding: 0px;
}

.grid.second div.grid-container {
   border: 3px solid white;
   border-radius: 15px;
   padding: 20px;
   display: flex;
   justify-content: space-between;
   align-items: center;
   flex-direction: row;
   background: transparent;
   height: 50px;
}
.grid.second div.grid-container:hover {
   background: #ffffff36;
}

.grid.grid.second p {
   -webkit-line-clamp: 1;
   line-clamp: 1;
}

.bottom {
   position: relative;
   overflow: hidden;
}

.bottom h1 {
   position: absolute;
   top: 50%;
   left: 50%;
   transform: translate(-50%, 0%);
   z-index: 1;
   width: 60%;
   text-align: center;
   font-family: 'Roboto', sans-serif;
   font-weight: 1000;
   font-size: 3.5em;
   line-height: 1.5em;
   letter-spacing: 8px;
   text-shadow: 1px 1px 15px rgba(0, 0, 0, 0.3);
}

.bottom img {
   width: 100%;
   height: auto;
   display: block;
}

@media only screen and (max-width: 1500px) {
   .bottom h1 {
      font-size: 2em !important;
      width: 90% !important;
      letter-spacing: 1px !important;
   }
   .grid {
      grid-template: none;
   }
}

@media only screen and (max-width: 900px) {
   /* .grid, */
   /* .head {
      padding-left: 5%;
      padding-right: 5%;
   } */
   .grid {
      grid-template-columns: repeat(1, minmax(300px, 1fr)) !important; 
   }
   .grid div.grid-container,
   .grid.second div.grid-container {
      height: auto;
   }
   .grid.grid.second p {
      -webkit-line-clamp: unset;
   }
   .head ul {
      width: 100%;
      gap: 15px;
   }
   .bottom h1 {
      font-size: 1em !important;
      width: 90% !important;
      letter-spacing: 1px !important;
   }
}
