.donation-header {
   display: none;
}

@media screen and (max-width: 991px) {
   /* donation header */
   .donation-header {
      width: 96%;
      padding: 2%;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      top: 0;
      z-index: 100;
   }

   .donation-header.scroll {
      top: -50px;
   }

   .donation-header button,
   .donation-header a {
      background-color: transparent;
      border: 0px;
   }
   .donation-header button:hover,
   .donation-header a:hover {
      cursor: pointer;
   }

   /* hamburger menu */
   .navbar {
      display: none !important;
   }

   .intro div {
      margin-top: 20px;
   }

   .hamburger-react div {
      background-color: white !important;
   }

   .hamburger > div.close {
      right: -80% !important;
   }

   .hamburger > div {
      top: 0px;
      right: 0px;
      overflow: hidden;
      position: fixed;
      height: 100vh;
      width: 80%;
      transition: all 0.5s ease-in-out;
      z-index: 3;
   }

   .hamburger > button {
      width: 30px !important;
      height: 30px !important;
      background-image: url('../static/assets/images/hamburger.png');
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 30px 30px;
   }

   .hamburger > div:nth-child(3).close {
      display: none;
   }

   .hamburger > div:nth-child(3) {
      opacity: 1;
      top: 0px;
      right: 0px;
      background-color: #00000071;
      height: 100vh;
      width: 200%;
      z-index: 498;
      transition: all 1s ease-in-out;
   }

   .hamburger > div:nth-child(3) > button,
   .hamburger > div:nth-child(3) > a {
      background-color: transparent;
      width: 100vh;
      z-index: 499;
      height: 100vh;
   }

   .hamburger .column {
      background-color: var(--color-orange);
      height: 100%;
      width: 100%;
      z-index: 3;
   }

   .hamburger .column > div:nth-child(1) {
      max-width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      background-color: var(--very-dark-green);
      padding: 5%;
   }

   .hamburger .column > div:nth-child(2) {
      display: flex;
      flex-direction: column;
   }

   .hamburger .elements {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
   }

   .hamburger > div {
      z-index: 500;
   }

   .hamburger .elements button,
   .hamburger .elements a {
      width: 100%;
      padding-left: 5%;
      padding-right: 5%;
      padding-bottom: 5%;
      padding-top: 5%;
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 15px;

      font-family: 'Roboto', sans-serif;
      color: white;
      font-weight: 500;
      font-size: 1em !important;
      letter-spacing: -0.6px;

      background-color: transparent;
      border: 0px;
   }

   .hamburger .elements button:hover,
   .hamburger .elements a:hover {
      background-color: #0000003a;
   }
}
