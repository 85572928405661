.carousel {
   width: calc(100vw); /* you put -20px here, i removed it -- there was a glitch on mobile */
   z-index: 9;
   position: absolute;
}

.sliding-banner {
   overflow: hidden;
   position: relative;
}

.partner-container {
   opacity: 0;
   padding-top: 2vh;
   width: 2000% !important;
   display: flex;
   gap: 30px;
   transition: opacity 0.4s ease;
   animation: slide 1500s linear infinite;
   animation-play-state: running;
}

.partner-container.faster {
   animation-duration: 800s;
}

.partner-container:hover {
   animation-play-state: paused;
}

.partner {
   cursor: pointer;
   position: relative;
   transition: unset !important;
   user-select: none;
}

.partner img {
   width: 60px;
   height: 60px;
}

.partner .partner-content {
   transition: all 0.4s ease;
}

.partner:hover .partner-content {
   transform: scale(1.1);
}

.carousel-box {
   display: flex;
   flex-direction: column;
   align-items: center;
   position: relative;
}
.carousel-box > div {
   position: absolute;
   display: flex;
   flex-direction: column;
   align-items: center;
   justify-content: center;
   top: 55px;
   opacity: 0.8;
}
.carousel-box > div > img {
   max-width: 30px;
   max-height: 10px;
}
.carousel-box > div > div {
   position: relative;
   background-color: black;
   padding: 10px;
   margin-top: -2px;
   border-radius: 10px;
}
.carousel-box > div > div > p {
   font-size: 1em;
   white-space: nowrap;
   font-weight: 300;
   opacity: 1 !important;
}

@keyframes slide {
   0% {
      opacity: 0;
      transform: translateX(0%);
   }
   0.1% {
      opacity: 1;
   }
   99.9% {
      opacity: 1;
   }
   100% {
      opacity: 0;
      transform: translateX(-100%);
   }
}

.carousel {
   top: 7em;
}

@media only screen and (min-width: 5000px) {
   .sliding-banner {
      padding: 50vh 0;
   }
}

@media only screen and (min-width: 3000px) {
   .carousel {
      top: 5rem;
   }
}

@media only screen and (max-width: 1700px) {
   .partner img {
      width: 50px;
      height: 50px;
   }
}

@media only screen and (max-width: 1500px) {
   .carousel {
      top: 4rem;
   }
}

@media only screen and (max-width: 1200px) {
   .carousel {
      top: 3rem;
   }
}

@media only screen and (max-width: 1000px) {
   .carousel {
      top: 1.5rem;
   }
   .partner img {
      width: 50px;
      height: 50px;
   }
}

@media only screen and (max-width: 800px) {
   .carousel {
      padding-top: 2em;
      top: -2rem;
   }
}

@media only screen and (max-width: 600px) {
   .carousel {
      padding-top: 7em;
      top: -7.5rem;
   }
}

@media only screen and (max-width: 500px) {
   .carousel {
      top: -7rem;
   }
   .earth-carousel {
      margin-top: 50px;
   }
   .partner-container {
      padding-top: 0;
   }
   .carousel-box img {
      width: 40px;
      height: 40px;
   }
}
