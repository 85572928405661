header {
   padding: 10px 20px;
   max-width: calc(1900px - 40px);
   margin: 0 auto;
}

header a {
   font-weight: 100;
   font-size: 14px;
   letter-spacing: 1.5px;
   line-height: 9px;
}

header a img {
   width: 40px;
   transition: all 0.3s ease-in-out;
}
header a img:hover {
   filter: brightness(1.1);
}

header div.multi-links {
   position: relative;
}
header div.multi-links div.menu {
   position: absolute;
   top: 30px;
   left: 0;
   list-style: none;
   padding: 0;
   max-height: 0 !important;
   overflow: hidden;
   transition: max-height ease-in 1s, opacity ease 0.8s;
   opacity: 0;
   z-index: 9;
}

header div.multi-links div.menu a {
   padding: 10px;
   border-radius: 5px;
   font-size: 12px;
   min-width: 100px;
}

header div.multi-links:hover div.menu {
   max-height: 1000px !important;
   opacity: 1;
}

.header-bar a.hover:hover {
   color: red !important;
}
