.image-mask {
   background: url('../static/assets/images/earthcrop.png') no-repeat;
   background-size: cover;
   background-position: center top;
   height: 530px !important;
   width: 2000px !important;
   border: 1px solid red;
 }

.home-header {
   display: flex;
   flex-direction: column;
   justify-content: start;
   align-items: center;
   width: 100%;
   position: relative;
}

.home-header .introduction {
   display: flex;
   align-items: center;
   gap: 40px;
}

.home-header .rot-text {
   width: 190px;
   font-size: 1em;
   text-align: left;
   display: flex;
   justify-content: flex-start;
   align-items: center;
}

.home-header .fade {
   transition: all 0.25s ease-in-out;
   opacity: 1;
}

.home-header .fade-out {
   transition: all 0.25s ease-in-out;
   opacity: 0;
}

.header-lh span:first-child {
   line-height: 40px !important;
}

.home-header .react-rotating-text-cursor {
   display: none;
}

.home-header .introduction > div {
   gap: 60px;
}

.home-header .introduction img {
   width: 550px;
}

.home-header .introduction h1 {
   color: red;
   font-size: 120px;
   letter-spacing: 25px;
   line-height: 0.8em;
}

.home-header .introduction p {
   font-family: 'Herborn';
   font-size: 1.5em;
   font-weight: 100;
   color: white;
   letter-spacing: 5px;
   text-align: center;
}

img[alt='earth'] {
   width: 100%;
}

.earth-carousel {
   display: flex !important;
   position: relative !important;
   overflow: hidden;
   /* transform: translateY(15vh); */
}

.earth-box {
   display: flex;
   flex-direction: column;
   position: absolute;
   gap: 15vh;
   max-width: 1900px;
   width: 100%;
   top: 50%;
   left: 50%;
   transform: translate(-50%, 0%);
   z-index: 99;
}

.earth-box > div {
   display: flex;
   
}

.earth-box > div p {
   font-family: 'Roboto';
   color: white !important;
   text-align: center !important;
   text-transform: uppercase !important;
   font-size: 1.7em;
   font-weight: 400 !important;
   line-height: 60px;
   letter-spacing: 2px;
   width: 70%;
}

.earth-box > div {
   justify-content: center;
   gap: 40px;
}

.earth-box > div div {
   display: flex;
   flex-direction: column;
   justify-content: center !important;
   align-items: center !important;
   background: transparent linear-gradient(180deg, var(--color-orange) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
   padding: 20px 25px;
   border-radius: 20px;
   width: 240px;
   border: 5px solid white;
}

.earth-box > div h1 {
   font-size: 3em;
   white-space: nowrap;
}

.earth-box > div h2 {
   font-size: 1.5em;
}

.carousel-logos {
   position: absolute;
}

.sliding-banner {
   padding: 0px !important;
   height: 40vw !important;
}



@media only screen and (max-width: 1500px) {
   .earth-box {
      gap: 20px;
   }
   .earth-box > div p {
      font-size: 2em;
      line-height: 1.3em;
      letter-spacing: 1px;
      width: 90%;
   }
}

@media only screen and (max-width: 1000px) {
   .earth-box {
      gap: 20px;
   }
   .earth-box > div p {
      font-size: 1.5em;
      line-height: 1.3em;
      letter-spacing: 1px;
      width: 90%;
   }
   .introduction > div:first-child {
      margin-top: 2rem !important;
   }

   .home-header .introduction h1 {
      font-size: 80px;
      letter-spacing: 20px;
      line-height: 0.8em;
   }

   .home-header .introduction img {
      width: 300px;
   }

   .home-header .introduction p {
      font-size: 1em;
      font-weight: 100;
   }
}

@media only screen and (max-width: 900px) {
   .earth-box {
      gap: 10px;
   }
   .earth-box > div {
      gap: 10px;
   }
   .earth-box > div div {
      width: 15%;
      height: 30px;
      border: 2px solid white;
   }
   .earth-box h2 {
      font-size: 0.7em !important;
      letter-spacing: 1px !important;
   }
   .home-header .introduction > div {
      gap: 10px;
   }
   .earth-box > div p {
      font-size: 0.7em;
      line-height: 1em;
      letter-spacing: 1px;
      width: 90%;
   }
}

@media only screen and (max-width: 600px) {
   .home-header .introduction {
      transform: translateY(0vh);
   }

   .introduction p {
      margin: 0rem 1rem;
   }

   .home-header .subtitle {
      display: none;
   }
}

/* @media only screen and (min-width: 0px) {
   .carousel-logos {
      margin-top: 1vw !important;
   }
}

@media only screen and (min-width: 600px) {
   .carousel-logos {
      margin-top: 6vw !important;
   }
}

@media only screen and (min-width: 900px) {
   .carousel-logos {
      margin-top: 10vw !important;
   }
}

@media only screen and (min-width: 1200px) {
   .carousel-logos {
      margin-top: 12vw !important;
   }
}

@media only screen and (min-width: 1900px) {
   .carousel-logos {
      margin-top: 14vw !important;
   }
}

@media only screen and (min-width: 2400px) {
   .carousel-logos {
      margin-top: 15.5vw !important;
   }
}

@media only screen and (min-width: 3800px) {
   .carousel-logos {
      margin-top: 16.5vw !important;
   }
}

@media only screen and (min-width: 4800px) {
   .carousel-logos {
      margin-top: 17vw !important;
   }
}

@media only screen and (min-width: 6400px) {
   .carousel-logos {
      margin-top: 22vw !important;
   }
} */
