.chains-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    padding: 20px;
    margin: 0 auto;
    max-width: 1200px;

}
 .chains-card {
    border-radius: 15px;
    border: 3px solid white;
    background: transparent linear-gradient(90deg, var(--color-orange-dark) 0%, var(--color-orange) 100%) 0% 0% no-repeat padding-box;
    overflow: hidden;
    gap: 20px;
    padding: 20px;
    height: 80px;
    transition: all 0.15s ease-in-out;
 }
 
 
    @media (max-width: 768px) {
        .chains-grid {
            grid-template-columns: repeat(1, 1fr);
        }
        .chains-card {
            height: 100px;
        }
    }