#root {
    /* margin: 0;
    font-family: Inter, system-ui, Avenir, Helvetica, Arial, sans-serif;
    line-height: 1.5;
    font-weight: 400;
    background-image: linear-gradient(to right, #ff5c01, #ffd302);
    font-synthesis: none;
    text-rendering: optimizeLegibility;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; */
  
    .bad-campaign-mint-button,
  .bad-aggregator-list-item-mint-button,
  .bad-aggregator-card-mint-button {
    background-color: #2c2d33;
    color: white;
  }

  .bad-campaign-mint-button:disabled,
  .bad-aggregator-list-item-mint-button:disabled {
    opacity: 0.7;
    cursor: not-allowed !important;
  }

  .bad-aggregator-list-item-items,
  .bad-aggregator-list-item-hourly-mints,
  .bad-aggregator-card-items,
  .bad-aggregator-card-hourly-mints {
    background-color: hsla(0, 0%, 100%, 0.4) !important;
  }

  .bad-aggregator-card {
    padding: 0;
    background-color: hsla(0, 0%, 100%, 0.4) !important;
    border: none;
    box-shadow: 0 0 transparent, 0 0 transparent,
      0 25px 50px -31px rgba(0, 0, 0, 0.25) !important;
  }

  .bad-aggregator-list-item {
    padding: 0;
    background-color: hsla(0, 0%, 100%, 0.4) !important;
    border: none;
    box-shadow: 0 0 transparent, 0 0 transparent,
      0 25px 50px -31px rgba(0, 0, 0, 0.25) !important;
  }

  .bad-campaign-card {
    background-color: hsla(0, 0%, 100%, 0.4) !important;
    border: 0.1px solid rgb(221, 221, 221) !important;
    box-shadow: 0 0 transparent, 0 0 transparent,
      0 25px 50px -31px rgba(0, 0, 0, 0.25) !important;
  }
  }