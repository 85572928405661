footer {
   background-color: var(--color-black);
   padding: 50px 20px;
   max-width: calc(1900px - 40px);
   margin: 0 auto;
}

footer a:not(.secondary) {
   font-size: 30px;
}

footer > div {
   width: 100%;
   justify-content: space-between !important;
   max-width: 1400px;
   /* padding: 0 50px 0 50px; */
}

footer .secondary {
   min-width: 200px;
   /* border: 1px solid yellow; */
}

footer .footer-gap {
   gap: 200px;
}

@media screen and (max-width: 900px) {
   footer .footer-gap {
      gap: 20px;
   }
}