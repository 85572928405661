.our-mission .multi-links {
   position: relative;
   min-width: 170px;
}

.multi-links div.menu {
   position: absolute;
   top: 30px;
   left: 0;
   list-style: none;
   padding: 20px 0 0 0;
   max-height: 0;
   overflow: hidden;
   transition: max-height ease-in 1s, opacity ease 0.8s;
   opacity: 0;
   z-index: 9;
}

.multi-links.btn:hover {
   background-color: transparent;
   cursor: pointer !important;
}

.multi-links {
   text-align: center;
}

.mission .multi-links div.menu a {
   padding: 10px 20px;
   background-color: var(--color-orange);
   border-radius: 10px;
   border: 2px solid white;
   min-width: 170px;
}

.mission .multi-links:hover div.menu {
   max-height: 1000px !important;
   opacity: 1;
}

.mission .multi-links:hover {
   cursor: default;
}

.mission a {
   color: white;
   line-height: 1 !important;
   font-size: 1em !important;
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
}
.multi-links div.menu a:hover, .multi-links .btn:hover {
   background-color: #e42525 !important;
}

.btn.primary {
   min-width: 170px !important;
}

.multi-links h3 {
   line-height: normal !important;
   margin-right: 10px;
}

.multi-links img,
.multi-links>a>div>div:first-child {
   width: 14px;
}

.multi-links div {
   display: flex;
   justify-content: space-between;
}

.getbonk>div:nth-child(2) {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   column-gap: 10px;
   row-gap: 15px;
}

.getbonk>div:nth-child(2) div {
   display: flex;
   justify-content: flex-start;
   align-items: center;
   gap: 20px;
   cursor: pointer;
}
.getbonk>div:nth-child(3)>img {
   width: 20px !important;
   transition: all 0.2s ease-in-out;
}
.getbonk>div:nth-child(3)>img:hover {
   width: 22px !important;
   cursor: pointer;
   opacity: 0.6;
}

.getbonk a {
   font-size: 1em;
   text-transform: uppercase;
   color: white;
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
}

.getbonk a:hover {
   color: #e6e6e6;
   cursor: pointer;
}

.getbonk p {
   font-size: 1em;
   text-transform: uppercase;
   color: white;
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
}

.getbonk img {
   width: 25px !important;
   height: 25px !important;
}

.usebonk > div {
   display: grid;
   grid-template-columns: repeat(2, 1fr);
   gap: 5px;
   column-gap: 20px;
   color: white;
   text-transform: uppercase;
   font-weight: 600;
   cursor: pointer;
}
.usebonk > div > div {
   display: flex;
   justify-content: space-between;
   border-bottom: 2px solid white;
   padding: 5px;
}
.usebonk .no-border {
   border-bottom: 0px;
}

.keyprojects h1 {
   margin-top: 3px;
   line-height: 0.78em !important;
   color: white;
   font-size: 3em;
   line-height: 0.8em;
}

.keyprojects>div div {
   display: flex;
   justify-content: center;
   flex-direction: column;
   align-items: center;
   width: 100%;
   height: 200px;
}

.keyprojects>div div h3 {
   color: white;
   font-family: Herborn;
   transition: all ease 0.4s;
   font-weight: 100;
   font-size: 1.3em;
   line-height: 1em;
   margin-top: 20px;
}

.keyprojects>div div p {
   margin-top: 10px;
   color: white;
   font-family: 'Roboto', sans-serif;
   transition: all ease 0.4s;
   font-weight: 300;
   font-size: 1em;
   text-align: center;
}

.tweets {
   display: flex;
   gap: 20px;
}

.tweets div {
   display: flex;
   flex-direction: column;
   width: 100%;
   gap: 20px;
}

.tweets div div {
   height: 100px;
   background-color: var(--color-orange);
   border-radius: 20px;
   display: flex;
   justify-content: center;
   align-items: center;
}

.news div:nth-child(3) h2 {
   margin-top: 1px;
}

.btn.more {
   text-transform: uppercase;
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
   letter-spacing: 1px;
}

.grid-container {
   display: grid;
   grid-template-columns: repeat(4, 1fr);
   gap: 10px;
}

@media screen and (max-width: 900px) {
   .grid-container {
      grid-template-columns: repeat(1, 1fr);
   }

   .multi-links .menu {
      opacity: 1 !important;
      max-height: 1000px !important;
      position: relative !important;
      top: 0 !important;
   }
   .multi-links .menu a {
      min-width: 10px !important;
   }
   .our-mission {
      flex-direction: column;
   }
   .usebonk > div > div:nth-last-child(2) {
      border-bottom: 2px solid white;
   }
   .keyprojects > div {
      flex-wrap: wrap;
   }
}
