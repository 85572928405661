.pie-container {
    display: flex;
    justify-content: center;
    align-items: center;
}
.recharts-legend-wrapper {
    background-color: white;
    padding: 10px;
    width: auto !important;
    border-radius: 20px;
    font-family: 'Roboto';
    text-transform: uppercase;
    font-weight: 500 !important;
}
.recharts-text {
    font-family: 'Roboto';
    text-transform: uppercase;
    font-weight: 500 !important;
}