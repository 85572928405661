.modal-background {
    width: 100%;
    height: 100%;
    background-color: #000000b9;
    position: fixed;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}
.modal-container {
    width: 75%;
    height: 60%;
    background-color: var(--color-orange-dark);
    position: fixed;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding: 3%;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    gap: 20px;
}
.modal-content {
    width: 100%;
    height: 100%;
    overflow-y: auto;
}
.modal-content > p {
    text-align: left;
    font-size: 1.3em;
    color: white;
}
.agreement-button {
    white-space: nowrap;
    height: 50px;
    font-size: 0.9em;
    padding-left: 2rem;
    padding-right: 2rem;
    border: 0px;
    border-radius: 7px;
    color: white;
    background-color: var(--color-red);
    cursor: pointer;
    text-transform: uppercase;
    transition: 0.70s;
    --hover: var(--color-red-darker);
    letter-spacing: 1px;
    font-weight: 600;
}
.agreement-button:hover {
    box-shadow: inset 0 -5em 0 0 var(--hover);
}