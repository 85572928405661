.VANISH {
   display: none;
}
:root {
   /* COLORS */
   --color-white-dark: #e0e0e0;

   --color-orange: #fc8e03;
   --color-orange-dark: #ff5c01;

   --color-yellow: #ffd302;
   --color-yellow-dark: #fc8e03;

   --color-red: #d40e26;
   --color-red-dark: #ad0d20;
   --color-red-darker: #850817;
   --color-red-text: #ff0000;

   --color-grey: #222934;
   --color-grey-dark: #000205;

   --color-black: #010c14;

   --color-green: #37ab07;

   /* SETTINGS */
   --border-radius: 20px;
}

.bg-od {
   background-color: var(--color-orange-dark);
}

body {
   margin: 0;
   /* background: transparent linear-gradient(90deg, var(--color-orange) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box; */
   background: transparent linear-gradient(45deg, #000205 0%, #2b3649 100%) 0% 0% no-repeat padding-box;
   /* background: #333; */
}

main {
   /* padding: 50px; */
   min-height: 50vh;
}

h1,
h2,
h3,
h4,
h5,
h6,
a {
   margin: 0;
   padding: 0;
   font-family: Herborn;
   transition: all ease 0.4s;
   font-weight: 100;
}

h1 {
   color: white;
   font-size: 30px;
}

h2 {
   color: white;
   font-size: 25px;
   letter-spacing: 4px;
}

h3 {
   color: white;
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
   font-size: 1em;
}

a {
   text-decoration: none;
   color: white;
}

a.secondary {
   color: var(--color-white-dark);
   font-family: 'Roboto', sans-serif;
}

p {
   font-family: 'Roboto', sans-serif;
   font-size: 20px;
   transition: all ease 0.4s;
   margin: 0;
}

p.white {
   color: white;
}

p.grey {
   color: var(--color-grey);
}

p.small {
   font-size: 12px;
}

p.large {
   font-size: 30px;
}

.white {
   color: white;
}

.red {
   color: var(--color-red-text);
}

.green {
   color: var(--color-green);
}

section {
   max-width: 1900px;
   margin: 0 auto;
   width: -webkit-fill-available;
   /* padding: 0 50px; */
}

.bg-yellow {
   background-color: var(--color-yellow);
}

.bg-orange {
   background-color: var(--color-orange);
}

.bg-dark-orange {
   background-color: var(--color-orange-dark);
}

.bg-dark {
   background-color: var(--color-grey-dark);
}

.bg-black {
   background-color: black;
}

.bg-yellow-gradient {
   background: transparent linear-gradient(90deg, var(--color-orange) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
}

.bg-primary-gradient {
   background: transparent linear-gradient(90deg, var(--color-orange) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
}

.bg-orange-gradient {
   background: transparent linear-gradient(90deg, var(--color-orange-dark) 0%, var(--color-yellow) 100%) 0% 0% no-repeat padding-box;
}

.bg-orange-secondary-gradient {
   background: transparent linear-gradient(180deg, var(--color-orange-dark) 0%, var(--color-yellow-dark) 100%) 0% 0% no-repeat padding-box;
}

.container {
   padding: 20px;
   max-width: 1200px;
   width: -webkit-fill-available;
   margin: 20px auto;
}

.btn {
   cursor: pointer;
   border: 2px solid white;
   border-radius: 10px;
   padding: 10px 20px;
   min-width: 100px;
   text-align: center;
   line-height: 13px;
}

.btn:hover {
   color: white;
   background-color: rgba(255, 255, 255, 0.33);
}

.btn.kp {
   padding: 10px 40px;
   border: 0;
   text-transform: uppercase;
   letter-spacing: 1px;
   display: flex;
   align-items: center;
   justify-content: center;
   background: transparent linear-gradient(180deg, var(--color-red-darker) 0%, var(--color-red-text) 100%) 0% 0% no-repeat padding-box;
   transition: all 0.2s ease-in-out;
   font-family: 'Roboto', sans-serif;
   font-weight: 600;
   letter-spacing: 2px;
   font-size: 1em;
   color: white;
}
.btn.kp:hover {
   transform: scale(1.04);
}

.top-border {
   border-top: 5px solid white;
}

table {
   border-collapse: collapse;
   font-family: 'Roboto', sans-serif;
   font-size: 1em;
}

table tr:first-child td {
   border-top: 1px solid white;
}

table tr td:first-child {
   text-align: left;
}

table tr td:last-child {
   text-align: right;
}

table td {
   padding: 5px;
   border-bottom: 1px solid white;
   color: white;
}
